import React from "react";
import _ from "lodash";
import {
  PencilIcon,
  VideoCameraIcon,
  CogIcon,
  LightBulbIcon,
} from "@heroicons/react/24/outline";

const steps = [
  {
    title: "Llena el formulario",
    description:
      "Un asesor experto te llamará para confirmar datos y agendar una cita",
    icon: PencilIcon,
  },
  {
    title: "Videollamada",
    description:
      "Te haremos un diseño 100% personalizado a tus necesidades y tu consumo eléctrico",
    icon: VideoCameraIcon,
  },
  {
    title: "Instalación",
    description:
      "Después de firmar el contrato instalaremos tu sistema solar y haremos todo el trámite de la CFE por ti",
    icon: CogIcon,
  },
  {
    title: "¡Listo!",
    description:
      "Disfruta de tu sistema solar, el mantenimiento va por nuestra cuenta",
    icon: LightBulbIcon,
  },
];

const BrightJourneySteps = ({}) => {
  return (
    <div className="lg:max-w-xl mx-auto bg-gray-50 px-1.5 py-2 sm:px-8 sm:py-8 rounded-lg">
      <h2 className="text-2xl font-medium">
        ¡Con Bright ahorrar es muy sencillo!
      </h2>

      <div className="flex flex-col space-y-6 mt-10">
        {steps.map((item, index) => (
          <div key={index} className="flex flex-row space-x-6">
            <div className="flex flex-shrink-0 items-center justify-center h-14 w-14 text-gray-700">
              <item.icon className="h-8 w-8" />
            </div>
            <div>
              <div className="text-md font-medium text-gray-900 flex-shrink-0	">
                {item.title}
              </div>
              <div className="mt-2 text-gray-500">{item.description}</div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default BrightJourneySteps;
